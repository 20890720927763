@if((modals$ | async)?.length ) {
<div class="modal" @fade>
    @for( modal of (modals$ | async); track modal.id;) {
    <div class="modal-backdrop" [class.left]="modal.data.style=='true'" [id]="'index'+$index"
        (click)="onBackdropClick($event, 'index'+$index, modal.id)">

        <div class="modal-content"  [class.dark]="modal.data.style=='dark'">

            <ng-container  #modalContainer></ng-container>

            <img class="modal-close" aria-label="close" src="assets/icons/dark/close.svg"
                (click)="close(modal.id)">
        </div>


    </div>
    }
</div>
}