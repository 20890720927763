import { Directive, ElementRef, Renderer2, HostListener, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CityService } from '../services/city.service';

@Directive({
    selector: '[appButtonList]',
    standalone: true,
})
export class ButtonListDirective implements OnInit {
    @Output() buttonClick = new EventEmitter<string>(); // Событие для клика по кнопке
    private buttonContainer: HTMLElement | null = null;
    private buttons: string[] = [];
    private buttonsSubtext: string[] = [];
    private globalClickListener = () => { }; // Ссылка на глобальный обработчик событий

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        private cityService: CityService // Инжектируем сервис
    ) {

        this.renderer.setStyle(this.el.nativeElement, 'cursor', 'pointer');
    }

    ngOnInit(): void {
        // Получаем список имен городов из сервиса
        this.buttons = this.cityService.getNames();
        this.buttonsSubtext = this.cityService.getStreets();
    }

    @HostListener('click')
    toggleButtonList(event?: MouseEvent): void {
        if (this.buttonContainer) {
            this.destroyButtonList();
        } else {
            this.createButtonList();
            this.addGlobalClickListener(); // Добавляем глобальный обработчик
        }
    }

    private createButtonList(): void {
        this.buttonContainer = this.renderer.createElement('div');
        this.renderer.addClass(this.buttonContainer, 'button-container');

        this.buttons.forEach((buttonLabel, index) => {
            const button = this.renderer.createElement('button');
            this.renderer.addClass(button, 'button-list-item');

            const text = this.renderer.createElement('p');
            text.textContent = String(buttonLabel);
            this.renderer.addClass(text, 'button-list-text');

            const subtext = this.renderer.createElement('p');
            subtext.textContent = String(this.buttonsSubtext[index]);
            this.renderer.addClass(subtext, 'button-list-subtext');

            this.renderer.appendChild(button, text);
            this.renderer.appendChild(button, subtext);

            this.renderer.listen(button, 'click', (event) => {
                event.stopPropagation();
                this.buttonClick.emit(buttonLabel);
                this.cityService.changeByName(buttonLabel, this.buttonsSubtext[index]); // Изменяем текущий город по имени
                this.destroyButtonList();
            });

            this.renderer.appendChild(this.buttonContainer, button);
        });
        this.renderer.setStyle(this.el.nativeElement, 'position', 'relative');
        this.renderer.appendChild(this.el.nativeElement, this.buttonContainer);
    }

    private destroyButtonList(): void {
        if (this.buttonContainer) {
            this.renderer.removeChild(this.el.nativeElement, this.buttonContainer);
            this.buttonContainer = null;
            this.removeGlobalClickListener(); // Удаляем глобальный обработчик
        }
    }

    private addGlobalClickListener(): void {
        this.globalClickListener = this.renderer.listen('document', 'click', (event: MouseEvent) => {

            if (!this.el.nativeElement.contains(event.target)) {
                this.destroyButtonList();
            }
        });
    }

    private removeGlobalClickListener(): void {
        if (this.globalClickListener) {
            this.globalClickListener();
            this.globalClickListener = () => { };
        }
    }
}
