import { Component, ElementRef, HostListener, Inject, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';
import { contacts } from "../../constants/constants"
import { RouterLink } from '@angular/router';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';
import { HeaderService } from '../../services/header.service';
import { PopupItemWrapper } from '../catalog/catalog-card/catalog-card.component';
import { PopupService } from '../../services/pupup.service';
import { SearchObject, SearchService } from '../../services/search.service';
import { CopyTextDirective } from '../../directives/copy-text.directive';
import { ButtonListDirective } from '../../directives/city.directive';
import { CityService } from '../../services/city.service';
import { City } from '../../data/city/city';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [ReactiveFormsModule, RouterLink, CopyTextDirective, ButtonListDirective],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  contacts = contacts;
  isHidden = false;
  checkboxControl!: FormControl;
  mobileWidth: number = 1150;
  mobileHeader: boolean = false;
  animation: "none" | "start" | "end" | "sleep" | null = null;
  animationStatus = false;
  animationInProgress: boolean = false;
  searchIsOpen: boolean = false;
  inputIsOpen = false;
  showSearchResult: boolean = false;
  city: City = new City();
  inputValue = '';
  searched: Array<SearchObject> = [];

  constructor(@Inject(PLATFORM_ID) private platformId: any,
    private headerService: HeaderService,
    private popupService: PopupService,
    private cityService: CityService,
    private searchService: SearchService, private renderer: Renderer2) {
    this.checkboxControl = new FormControl(false);
    this.city = this.cityService.currentCity;
    this.cityService.cityChanged$.subscribe(v => this.city = v);
    this.headerService.headerVisible$.subscribe(isVisible => {
      if (this.mobileHeader) {
        this.mobileHeader = false;
        document.body.style.overflow = '';
        document.body.style.marginRight = '';
        (document.querySelector("#mobile-header")! as HTMLElement).style.paddingRight = ``;
        this.endAnimation();
      }
    });
    this.OnResize();
  }


  closeSearch() {
    this.inputIsOpen = false;
    this.showSearchResult = false;
  }

  lastInputValue = "";
  currentItemsLen = 0;
  maxSearched = 20;
  addedLimit = false;
  startSearch = false;
  onEnter() {
    //console.log(`this.startSearch = ${this.startSearch}`);
    if (this.startSearch) return;
    this.startSearch = true;
    //console.log("начал поиск");
    //console.log(`значения при выполнении функции${ this.currentItemsLen}   ${ this.maxSearched}  ${this.inputValue}`);
    if (this.lastInputValue != this.inputValue) {
      this.lastInputValue = this.inputValue;
      this.currentItemsLen = 0;
      this.maxSearched = 0;
      this.addedLimit = false;
      this.searched = [];
    }
    this.lastInputValue = this.inputValue;
    if (this.currentItemsLen == this.maxSearched) {
      this.maxSearched += 20;
      //console.log(`значения ${ this.currentItemsLen}   ${ this.maxSearched}  ${this.inputValue}`);
      this.searchService.search(this.inputValue, this.currentItemsLen, this.maxSearched)
        .subscribe({
          next: (data) => {
            this.searched.push(...data); // Добавляем полученные значения в массив
            this.currentItemsLen = this.searched.length;
            this.startSearch = false;

            if (this.currentItemsLen == 0) {
              this.searched = [new SearchObject({ title: "Ничего не найдено", text: "Попробуйте другой запрос", button: { text: "", type: "popup", props: ['', ''] } })];
              this.addedLimit = true;
            }
          },
          error: (error) => {
            //console.error('Ошибка при получении данных', error); // Обработка ошибок
            this.startSearch = false;
          }
        });

    } else {
      this.searched = [new SearchObject({ title: "Ничего не найдено", text: "Попробуйте другой запрос", button: { text: "", type: "popup", props: ['', ''] } })];
      this.startSearch = false;
      this.addedLimit = true;
    }
  }
  MakeRequest(object: SearchObject) {
    if (object.button.text != "") {
      //console.log(object.button.props);
      this.closeSearch();
      const contentComponent = PopupItemWrapper;
      this.popupService.open(contentComponent, { data: object.button.props, style: "dark", customText: true });
    }
  }
  onFocus(): void {
    this.inputIsOpen = true;
    this.showSearchResult = true;
  }
  onScroll(event: any): void {
    event.preventDefault(); // Предотвращаем действие по умолчанию
    // console.log("scroll search");
    const element = event.target;
    //console.log(`${ element.scrollHeight}   ${element.scrollTop}  ${element.clientHeight}`);
    const atBottom = Math.abs(element.scrollHeight - element.scrollTop - element.clientHeight) < 5;
    if (atBottom) element.scrollTop -= 1;

    if (element.scrollHeight > 0) {
      if (atBottom && this.currentItemsLen == this.maxSearched) {
        //console.log("search scroll on Enter");
        this.onEnter();

      } else if (atBottom && this.currentItemsLen < this.maxSearched && !this.addedLimit && !this.startSearch) {
        //console.log("atBottom && this.currentItemsLen < this.maxSearched && !this.addedLimit");
        this.searched.push(new SearchObject({ title: "Все что найдено", text: "", button: { text: "", type: "popup", props: ['', ''] } }));
        this.addedLimit = true;
      }
    }
  }

  onBlur(value: string): void {
    if (!value.trim()) {

      this.inputIsOpen = false;
    }
  }
  searchText() {
    if (this.inputIsOpen) {
      this.onEnter();
    } else {
      this.inputIsOpen = true;
      this.showSearchResult = true;
      (document.querySelector("#input2Element") as HTMLInputElement).focus();
    }
  }
  onInput(value: string): void {
    this.inputValue = value;
  }

  scrollToFooter() {
    const footer = this.renderer.selectRootElement('#footer-contacts-route', true);
    footer.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
  scrollToMap() {
    const footer = this.renderer.selectRootElement('#footer-map-route', true);
    footer.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  @ViewChild('search1', { static: true }) element1!: ElementRef;
  @ViewChild('searchRes1', { static: true }) element2!: ElementRef;

  @ViewChild('search2', { static: true }) element3!: ElementRef;
  @ViewChild('searchRes2', { static: true }) element4!: ElementRef;


  // Обработчик клика по документу
  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {
    if (this.showSearchResult) {
      if (window.innerWidth > this.mobileWidth) {
        const clickedInsideElement1 = this.element1.nativeElement.contains(event.target);
        const clickedInsideElement2 = this.element2.nativeElement.contains(event.target);
        if (!clickedInsideElement1 && !clickedInsideElement2) {
          this.closeSearch();
        }
      } else {
        const clickedInsideElement1 = this.element3.nativeElement.contains(event.target);
        const clickedInsideElement2 = this.element4.nativeElement.contains(event.target);
        if (!clickedInsideElement1 && !clickedInsideElement2) {
          this.closeSearch();
        }
      }
    }


  }
  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (scrollTop > 0) {
      this.isHidden = true;
    } else {
      this.isHidden = false;
    }
  }

  helpWithRepair() {

    const contentComponent = PopupItemWrapper;
    if (isPlatformBrowser(this.platformId)) {
      this.popupService.open(contentComponent, { data: ["page-form", document.title, "Кнопка \"Ремонт РВД\" в шапке сайта",], style: "dark" });
    }
  }
  helpWithPodbor() {
    const contentComponent = PopupItemWrapper;
    if (isPlatformBrowser(this.platformId)) {
      this.popupService.open(contentComponent, { data: ["page-form", document.title, "Кнопка \"помощь с подбором\" в шапке сайта"], style: "dark" });
    }
  }

  @HostListener('window:resize', ['$event'])
  OnResize() {
    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth > this.mobileWidth) {

        if (this.mobileHeader) {
          document.body.style.overflow = '';
          document.body.style.marginRight = '';
          (document.querySelector("#mobile-header")! as HTMLElement).style.paddingRight = ``;
          this.endAnimation();
          this.mobileHeader = false;
        }
      }

    }
  }
  hideMobile() {
    this.menuClick();
  }

  menuClick() {
    if (this.animationInProgress) return;
    this.animationInProgress = true;
    this.mobileHeader = !this.mobileHeader;
    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth < this.mobileWidth) {
        if (this.mobileHeader) {
          document.body.style.overflow = 'hidden';
          this.animation = "start";
          this.startAnimation();
        } else {
          document.body.style.overflow = '';
          document.body.style.marginRight = '';
          (document.querySelector("#mobile-header")! as HTMLElement).style.paddingRight = ``;
          this.endAnimation();
        }
      }
    }
  }
  startAnimation() {
    if (this.animation == "start") {
      this.animationStatus = true;
      this.animation = "end";
      setTimeout(() => {
        this.animationInProgress = false;
        const scrollBarWidth = window.innerWidth - document.body.offsetWidth;
        document.body.style.marginRight = `${scrollBarWidth}px`;
        (document.querySelector("#mobile-header")! as HTMLElement).style.paddingRight = `${scrollBarWidth}px`;

      }, 400);
    }
  }
  endAnimation() {
    if (this.animation == "end") {
      this.animation = "start";
      setTimeout(() => { this.animationStatus = false; this.animationInProgress = false }, 200);
    }
  }
}

