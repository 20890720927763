import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { BottomComponent } from '../shared/components/bottom/bottom.component';
import { HeaderComponent } from '../shared/components/header/header.component';
import { PopupStackComponent } from '../shared/components/popup-stack/popup-stack.component';
import { NgxMaskDirective } from 'ngx-mask';
import { NotificationsComponent } from "../shared/components/notifications/notifications.component";
import { isPlatformBrowser } from '@angular/common';
import { filter } from 'rxjs';
import { HeaderService } from '../shared/services/header.service';
import { SeoService } from '../shared/services/seo.service';
import { contacts } from '../shared/constants/constants';
import { CopyTextDirective } from '../shared/directives/copy-text.directive';
import { CityCookieService, CityService } from '../shared/services/city.service';
import { City } from '../shared/data/city/city';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CopyTextDirective,
    RouterOutlet, BottomComponent, HeaderComponent, PopupStackComponent, NgxMaskDirective, NotificationsComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'Главная | РВД-Сервис';
  contacts = contacts;

  city: City = new City();

  constructor(private route: ActivatedRoute, private router: Router, private seo: SeoService,
    private headerService: HeaderService,
    private cityCookie: CityCookieService,
    @Inject(PLATFORM_ID) private platformId: any,
    private cityService: CityService,
  ) {

    this.seo.updateTitle("Главная | РВД-Сервис");
    this.seo.updateImage('assets/images/seo/rvd.png');
    this.seo.updateDescription("Продажа и ремонт рукавов, шлангов высокого давления (РВД) в Нижнем Новгороде и Дзержинске. Купите качественные РВД для гидравлических систем. Ремонт и изготовление РВД по вашим требованиям. Широкий выбор, низкие цены.");
    this.seo.updateKeywords("РВД, рукава высокого давления, гидравлические шланги, продажа РВД, ремонт гидравлических рукавов, изготовление РВД, купить РВД, рвд давление, рвд высокого давления, рвд цена, обжим рвд, рвд сервис, гидравлические рвд, фитинги для рвд, рвд 2sn, рвд 1sn, рвд Нижний Новгород, рвд Дзержинск.");
  }
  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.cityCookie.getCookies();
      this.cityService.cityChanged$.subscribe(v => this.city = v);




    }
    var result: string = "";
    //categoryItems.forEach((v,i)=> result +=`id:${i}    ${v.title},тип: ${v.button1.props[0] == "category"?'категория':'предмет'} \n`);
    // dataStore.forEach(item => {
    //   if (item.name.toLowerCase().includes("netflex")) {
    //     item.table?.rows.forEach(row => {
    //       result += `Рукав гидравлический DN ${row.cells[0].text} "${item.name}" \n`
    //     })
    //   }
    // })
    /*
      [
                  new TableRow(["Внутренний диаметр", "Внешний диаметр", "Рабочее давление", "Разрывное давление", "Радиус изгиба", "Вес на 100 м"]),
                  new TableRow(["мм", "мм", "МПа", "МПа", "мм", "кг/100 м"]),
              ],
              [
                  new TableRow(["19", "29", "5,0", "1,0", "3,0", "50"]),
                  new TableRow(["25", "35", "5,0", "1,0", "3,0", "60"]),
                  new TableRow(["32", "42", "5,0", "1,0", "3,0", "90"]),
                  new TableRow(["38", "48", "5,0", "1,0", "3,0", "100"]),
                  new TableRow(["50", "60", "5,0", "1,0", "3,0", "140"]),
                  new TableRow(["65", "77", "6,0", "1,0", "3,0", "230"]),
                  new TableRow(["75", "88", "6,5", "1,0", "3,0", "270"]),
                  new TableRow(["100", "114", "7,0", "1,0", "3,0", "390"]),
                  new TableRow(["125", "141", "8,0", "1,0", "3,0", "600"]),
                  new TableRow(["152", "168", "8,0", "1,0", "3,0", "790"]),
              ] */
    //console.log(result);
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        this.headerService.setHeaderVisible(false);
        this.trackNavigation(event);
      });
  }

  trackNavigation(event: NavigationEnd): void {
    if (isPlatformBrowser(this.platformId)) {
      if (!event.url.includes('#'))
        window.scroll({ top: 0, left: 0 });
      this.route.fragment.subscribe(fragment => {
        if (fragment) {
          const element = document.getElementById(fragment);
          if (element) {

            element.scrollIntoView();
          }
        }
      });
    }
  }

}
